<template>
    <CSDialog
        class="tenant-detail-dialog"
        :dialog-visible="newInWarehouseInfo"
        @onClose="newInWarehouseInfo = false"
        @onConfirm="jugement()"
        dialog-title="添加入库"
    >
        <div
            slot="dialog-content"
        >
            <div style="max-height:977px;overflow-y:auto;font-size:24px;padding:30px">
                <div class="preview-tenant" style="line-height: 1;">
                    <p class="field">
                        <span style="margin-top:1px;" class="field-label">物品</span>
                        <CSSelect iWidth="36px" height="40px" style="width:310px;">
                            <select style="padding-left:5px;width:220px;color: #999999" v-model="newWarehouseDetail.itemName">
                                <option selected value="">请选择</option>
                                <option
                                    v-for="item in itemList"
                                    :value="item.id"
                                    :key="item.id"
                                >
                                    {{item.name}}
                                </option>
                            </select>
                        </CSSelect>
                        <span style="margin:1px;margin-left:20px">该物品当前剩余数量：{{newWarehouseDetail.itemQuantity}}</span>
                    </p>
                    <p class="field">
                        <span style="margin-top:1px;" class="field-label">出库单价(元)</span>
                        <input  style="
                                    width:310px;
                                    height:40px;
                                    padding:0px 10px;
                                    border:1px solid #979797;
                                    border-radius:4px"
                                type="text" placeholder="1-999999" v-model="newWarehouseDetail.itemOutPrice"/>
                    </p>
                    <p class="field">
                        <span style="margin-top:1px;" class="field-label">入库单价(元)</span>
                        <input 	style="
                                    width:310px;
                                    height:40px;
                                    padding:0px 10px;
                                    border:1px solid #979797;
                                    border-radius:4px"
                                type="text" placeholder="1-999999" v-model="newWarehouseDetail.itemInPrice"/>
                    </p>
                    <p id="timeField" class="field">
                        <span style="margin-top:1px;" class="field-label">入库时间</span>
                        <CSSelect iWidth="36px" height="40px" style="width:310px">
                            <el-date-picker
                                style="font-size:18px; width: 270px;"
                                type="datetime"
                                format="yyyy-MM-dd HH:mm"
                                value-format="yyyy-MM-dd HH:mm"
                                placeholder="请选择归还时间"
                                v-model="newWarehouseDetail.itemInDate"
                                :editable="false"
                            >
                            </el-date-picker>
                        </CSSelect>
                    </p>
                    <p class="field">
                        <span style="margin-top:1px;" class="field-label">入库数量</span>
                        <input 	style="
                                    width:310px;
                                    height:40px;
                                    padding:0px 10px;
                                    border:1px solid #979797;
                                    border-radius:4px"
                                type="text" placeholder="1-9999" v-model="newWarehouseDetail.itemInQuantity"/>
                    </p>
                    <p class="field">
                        <span style="margin-top:1px;" class="field-label">验收人</span>
                        <CSSelect iWidth="36px" height="40px" style="margin-right:20px;width:310px">
                            <select v-model="newWarehouseDetail.itemAcpStation"
                                    @change="getAcpStationStaff(newWarehouseDetail.itemAcpStation)"
                                    style="color: #999999"
                            >
                                <option selected value="">请选择</option>
                                <option
                                    v-for="acpStation in itemAcpStationList"
                                    :value="acpStation.id"
                                    :key="acpStation.id"
                                >
                                    {{acpStation.name}}
                                </option>
                            </select>
                        </CSSelect>
                        <CSSelect iWidth="36px" height="40px" v-show="newWarehouseDetail.itemAcpStation !== ''" style="width:310px">
                            <select v-model="newWarehouseDetail.itemAcpWorker">
                                <option selected value="">请选择员工</option>
                                <option
                                    v-for="acpStaff in itemAcpWorkerList"
                                    :value="acpStaff.id"
                                    :key="acpStaff.id"
                                >
                                    {{acpStaff.name}}
                                </option>
                            </select>
                        </CSSelect>
                    </p>
                    <p class="field">
                        <span class="field-label">附件</span>
                        <span style="display:inline-block;width:500px;vertical-align: top;">
                            <span>
                                <span
                                    v-show="JSON.stringify(newWarehouseDetail.itemFile) !== '[]'"
                                    v-for="(item,index) in newWarehouseDetail.itemFile" :key="index"
                                >
                                    <span
                                        style="
                                            width:300px;
                                            text-overflow:ellipsis;
                                            overflow:hidden;
                                            white-space:nowrap;
                                            display:inline-block;
                                            vertical-align:middle;
                                        "
                                    >{{item}}</span>
                                    <span style="vertical-align:middle" @click="deleteFile(index)" class="allow-click">删除</span>
                                </span>
                            </span>
                            <br v-show="JSON.stringify(newWarehouseDetail.itemFile) !== '[]'"/>
                            <span style="vertical-align:super" class="allow-click" @click="selectFile()">上传</span>
                        </span>
                        <input accept="image/*,.xls,.doc,.pdf" :v-model="newWarehouseDetail.itemFile" @change="uploadFile($event)" type="file" id="uploadBtn" hidden/>
                    </p>
                    <p class="field">
                        <span class="field-label">备注</span>
                        <textarea
                            style="
                                width:580px;
                                height:180px;
                                padding:10px;
                                resize:none;
                                border:0px;
                                border-radius:10px;
                                background-color:#F0F0F0;
                                vertical-align: top;
                                "
                            placeholder="限200个字" v-model="newWarehouseDetail.itemNote"
                            maxlength="200"
                        ></textarea>
                    </p>
                    <span style="
                            margin-left:240px;
                            font-size:20px;
                            color:#999999;
                            line-height:28px;
                    ">
                        <svg
                            class="icon"
                            aria-hidden="true"
                        >
                            <use xlink:href="#icon-menua-zu92"></use>
                        </svg>
                        确定添加后，将无法修改信息，请仔细认真检查所填信息。
                    </span>
                </div>
            </div>
        </div>
    </CSDialog>
</template>

<script>

import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";

import { ORG_LEVEL } from "@/constant";

import {
	queryDepartmentUrl,
	queryDepartmentStaffUrl,
} from "@/requestUrl";

export default {
    components:{
        CSSelect,
        CSDialog,
    },
    data(){
        return{
            newInWarehouseInfo:false,
            itemList: [],
            newWarehouseDetail:{
                itemName:"",
                itemOutPrice:"",
                itemInPrice:"",
                itemInDate:"",
                itemInQuantity:"",
                itemAcpStation:"",
                itemAcpWorker:"",
                itemQuanlity:null,
                itemNote:"",
                itemFile:[],
            },
            itemAcpStationList:[],
            itemAcpWorkerList:[],
        }
    },
    created(){
        this.$vc.on(this.$route.path, "newWarehouseItem","in",(data)=>{
            this.newInWarehouseInfo = data.boolean;
            this.itemList = data.itemList;
            this.getAcpStation();
            var date = this.$vc.dateFormat(new Date().getTime());
            date = date.substr(0,date.length-3);
            this.newWarehouseDetail.itemInDate = date;
        })
    },
    methods:{
        deleteFile(index){
            this.newWarehouseDetail.itemFile.splice(index,1);
        },
        selectFile(){
            $("#uploadBtn").trigger("click");
        },
        uploadFile(e){
            const files = e.target.files;
            if(files && files.length > 0){
                const file = files[0];
                if(/.(gif|jpg|jpeg|png|gif|jpg|png)$/.test(file.name)){
                    if(file.size > 1024 * 1024 * 2){
                        this.$vc.toast("图片大小不能超过 2MB!")
                        return false;
                    }
                    this.$fly.putFile(file).then(res => {
                        this.newWarehouseDetail.itemFile.push(res.name);
                    });
                }else{
                    this.$fly.putFile(file).then(res => {
                        this.newWarehouseDetail.itemFile.push(res.name)
                    })
                }
            }
        },
        getAcpStation(){
            this.$fly.post(queryDepartmentUrl,{
                regionCode:this.$vc.getCurrentRegion().code,
                level:ORG_LEVEL.DEPARTMENT,
            })
            .then(res=>{
                this.itemAcpStationList = res.data;
            })
        },
        getAcpStationStaff(index){
            var stations = this.itemAcpStationList;
            for(var i = 0;i < stations.length;i++){
                if(stations[i].id === index){
                    var departmentCode = stations[i].code;
                    this.$fly.post(queryDepartmentStaffUrl,{
                        regionCode:this.$vc.getCurrentRegion().code,
                        departmentCode,
                    })
                    .then(res=>{
                        res.data.forEach(item=>{
                            if(!item.resignTime){
                                this.itemAcpWorkerList.push(item)
                            }
                        })
                    })
                    break;
                }
            }
        },
        jugement(){
            if(this.jugementWarehouse()){
                var newWarehouse = this.newWarehouseDetail;
                newWarehouse.itemInPrice = +newWarehouse.itemInPrice;
                newWarehouse.itemOutPrice = +newWarehouse.itemOutPrice;
                newWarehouse.itemInQuantity = +newWarehouse.itemInQuantity;
                this.$vc.emit(this.$route.path, "newWarehouseItem","done",{
                    itemInfo:newWarehouse,
                })
                this.newInWarehouseInfo = false;
                this.newWarehouseDetail = {
                    itemName:"",
                    itemOutPrice:"",
                    itemInPrice:"",
                    itemInDate:"",
                    itemInQuantity:"",
                    itemAcpStation:"",
                    itemAcpWorker:"",
                    itemQuanlity:0,
                    itemNote:"",
                    itemFile:[],
                }
            }
        },
        jugementWarehouse(){
            var itemOutPrice = +this.newWarehouseDetail.itemOutPrice;
            var itemInPrice = +this.newWarehouseDetail.itemInPrice;
            var itemInQuantity = +this.newWarehouseDetail.itemInQuantity;
            if(itemOutPrice === 0){
                this.$vc.toast("请输入出库单价");
                return false;
            }else if(itemOutPrice <= 0 || itemOutPrice > 999999){
                this.$vc.toast("出库价格应在1-999999之间");
                return false;
            }else if(!Number(itemOutPrice)){
                this.$vc.toast("请输入正确的数字格式")
                return false;
            }else if(String(itemOutPrice).indexOf(".") !== -1 && String(itemOutPrice).slice(String(itemOutPrice).indexOf('.')).length >= 3){
                this.$vc.toast("请保留小数点后一位");
                return false;
            }else if(itemInPrice === 0){
                this.$vc.toast("请输入入库单价");
                return false;
            }else if(itemInPrice <= 0 || itemInPrice > 999999){
                this.$vc.toast("入库价格应在1-999999之间");
                return false;
            }else if(!Number(itemInPrice)){
                this.$vc.toast("请输入正确的数字格式")
                return false;
            }else if(String(itemInPrice).indexOf(".") !== -1 && String(itemInPrice).slice(String(itemInPrice).indexOf('.')).length >= 3){
                this.$vc.toast("请保留小数点后一位");
                return false;
            }else if(itemInQuantity <= 0 || itemInQuantity > 9999){
                this.$vc.toast("入库数量应在1-9999之间");
                return false;
            }else{
                return this.$vc.validate.validate(
                    {
                        newWarehouse:this.newWarehouseDetail,
                    },
                    {
                        "newWarehouse.itemName":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择物品",
                            }
                        ],
                        "newWarehouse.itemInDate":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择入库时间",
                            }
                        ],
                        "newWarehouse.itemInQuantity":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择入库数量",
                            },
                            {
                                limit:"num",
                                param:"",
                                errInfo:"请输入数量正确格式"
                            }
                        ],
                        "newWarehouse.itemAcpStation":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择领取部门",
                            }
                        ],
                        "newWarehouse.itemAcpWorker":[
                            {
                                limit:"required",
                                param:"",
                                errInfo:"请选择领取人",
                            }
                        ],
                    }
                )
            }
        }
    },
    watch:{
        "newWarehouseDetail.itemAcpStation":function(){
			this.newWarehouseDetail.itemAcpWorker = "";
            this.itemAcpWorkerList = [];
		},
        "newWarehouseDetail.itemName":function(val){
            let itemArr = this.itemList;
            this.newWarehouseDetail.itemQuantity = "";
            if(val !== ""){
                for(var i = 0;i<=itemArr.length;i++){
                    if(val === itemArr[i].id){
                        this.newWarehouseDetail.itemQuantity = itemArr[i].currentCount;
                        break;
                    }
                }
            }
        }
    }
}
</script>

<style scoped>
.tenant-detail-dialog .preview-tenant > p {
    margin-bottom: 20px;
}

.tenant-detail-dialog .preview-tenant .field-label {
    vertical-align: middle;
}
</style>
