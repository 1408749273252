<template>
  <div id="Warehouse">
    <CSTabBar
      :tabbar="tabBarList"
      :checkedTab="tabBarType"
      @changeTabBar="changeTabBar"
    ></CSTabBar>
    <div id="searchBox" class="filter-panel">
      <div v-show="tabBarType === 0">
        <CSSelect style="margin-right: 10px; width: 210px">
          <el-date-picker
            v-model="searchTerm.startDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span
          style="
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 15px 0;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        ></span>
        <CSSelect style="margin-left: 10px; margin-right: 20px; width: 210px">
          <el-date-picker
            v-model="searchTerm.endDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            :editable="false"
          ></el-date-picker>
        </CSSelect>
        <CSSelect style="margin-right: 20px">
          <select style="width: 146px" v-model="searchTerm.itemName">
            <option selected value="">全部物品</option>
            <option v-for="item in itemList" :value="item.id" :key="item.id">
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px; width: 146px">
          <select v-model="searchTerm.itemType">
            <option selected value="">全部物品类型</option>
            <option
              v-for="itemType in itemTypeList"
              :value="itemType.id"
              :key="itemType.id"
            >
              {{ itemType.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px">
          <select style="width: 146px" v-model="searchTerm.supplier">
            <option selected value="">全部供应商</option>
            <option
              v-for="itemSupplier in supplierList"
              :value="itemSupplier.id"
              :key="itemSupplier.id"
            >
              {{ itemSupplier.companyName }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px; width: 146px">
          <select v-model="searchTerm.itemReason">
            <option selected value="">全部出库原因</option>
            <option
              v-for="itemAcpPer in itemReasonList"
              :value="itemAcpPer.id"
              :key="itemAcpPer.id"
            >
              {{ itemAcpPer.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px; width: 146px">
          <select v-model="searchTerm.itemIsReturn">
            <option selected value="">归还状态不限</option>
            <option
              v-for="itemIsReturn in itemIsReturnList"
              :value="itemIsReturn.id"
              :key="itemIsReturn.id"
            >
              {{ itemIsReturn.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 30px">
          <select style="width: 146px" v-model="searchTerm.accepctor">
            <option selected value="">全部领取人</option>
            <option
              v-for="itemAcpPer in itemAcpPerList"
              :value="itemAcpPer.id"
              :key="itemAcpPer.id"
            >
              {{ itemAcpPer.name }}
            </option>
          </select>
        </CSSelect>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="getWarehouseItem()"
        >
          查询
        </button>
      </div>
      <div v-show="tabBarType === 1">
        <CSSelect style="margin-right: 10px; width: 210px">
          <el-date-picker
            v-model="searchTerm.startDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择开始时间"
            prefix-icon="el-icon-time"
            :editable="false"
          >
          </el-date-picker>
        </CSSelect>
        <span
          style="
            display: inline-block;
            vertical-align: middle;
            margin: 0 0 15px 0;
            height: 2px;
            width: 20px;
            margin-right: 0;
            background-color: #999;
          "
        ></span>
        <CSSelect style="margin-left: 10px; margin-right: 20px; width: 210px">
          <el-date-picker
            v-model="searchTerm.endDate"
            type="datetime"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择结束时间"
            prefix-icon="el-icon-time"
            :editable="false"
          ></el-date-picker>
        </CSSelect>
        <CSSelect style="margin-right: 20px">
          <select style="width: 146px" v-model="searchTerm.itemName">
            <option selected value="">全部物品</option>
            <option v-for="item in itemList" :value="item.id" :key="item.id">
              {{ item.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px; width: 146px">
          <select v-model="searchTerm.itemType">
            <option selected value="">全部物品类型</option>
            <option
              v-for="itemType in itemTypeList"
              :value="itemType.id"
              :key="itemType.id"
            >
              {{ itemType.name }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 20px">
          <select style="width: 146px" v-model="searchTerm.supplier">
            <option selected value="">全部供应商</option>
            <option
              v-for="itemSupplier in supplierList"
              :value="itemSupplier.id"
              :key="itemSupplier.id"
            >
              {{ itemSupplier.companyName }}
            </option>
          </select>
        </CSSelect>
        <CSSelect style="margin-right: 30px">
          <select style="width: 146px" v-model="searchTerm.itemAcpPer">
            <option selected value="">全部验收人</option>
            <option
              v-for="itemRecPer in itemAcpPerList"
              :value="itemRecPer.id"
              :key="itemRecPer.id"
            >
              {{ itemRecPer.name }}
            </option>
          </select>
        </CSSelect>
        <button
          type="button"
          class="btn btn-primary btn-sm"
          @click="getWarehouseItem()"
        >
          查询
        </button>
      </div>
    </div>
    <div id="tableBox" class="result-panel">
      <CSTable v-show="tabBarType === 0" :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="sticky-right">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="newOutWarehouse()"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                出库
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="margin-left: 20px"
                @click="downloadOutFile('out')"
              >
                下载出库记录
              </button>
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <span style="vertical-align: super; margin-left: 5px"
                  >选择</span
                >
              </div>
            </th>
            <th>出库时间</th>
            <th>物品名称</th>
            <th>出库单价<span>(元)</span></th>
            <th>出库数量</th>
            <th>出库后剩余数量</th>
            <th>出库原因</th>
            <th>归还时间</th>
            <th>附件</th>
            <th>备注</th>
            <th>领取人</th>
            <th>创建人</th>
            <th>操作</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-if="loading">
            <td :colspan="12">
              <div style="text-align: center">暂无数据</div>
            </td>
          </template>
          <template v-for="(itemInfo, itemInfoId) in outWarehouseList">
            <tr :data-id="itemInfoId" :key="itemInfoId">
              <td>
                <input
                  v-model="targetOutFileList"
                  :value="itemInfo.id"
                  type="checkbox"
                />
              </td>
              <td class="date-td">{{ itemInfo.deliverTime }}</td>
              <td>
                <span class="allow-click" @click="itemInterface(itemInfo)">
                  {{ itemInfo.goodsName }}
                </span>
              </td>
              <td>{{ itemInfo.deliverPrice }}</td>
              <td>{{ itemInfo.deliverCount }}</td>
              <td>{{ itemInfo.residueCount }}</td>
              <td>{{ itemInfo.causeName }}</td>
              <td class="date-td">{{ itemInfo.returnTime }}</td>
              <td>
                <span
                  v-if="itemInfo.annex !== '[]' && itemInfo.annex"
                  class="allow-click"
                  @click="fileInterface(itemInfo, tabBarType)"
                  >查看</span
                >
                <span v-else> - </span>
              </td>
              <td>
                <span
                  v-if="itemInfo.comment"
                  class="allow-click"
                  @click="noteInterface(itemInfo)"
                  >查看</span
                >
                <span v-else> - </span>
              </td>
              <td>
                {{ itemInfo.receiverUserName }}【{{
                  itemInfo.receiverUserJobTitle
                }}】
              </td>
              <td>
                {{ itemInfo.createUserName }}【{{
                  itemInfo.createUserJobTitle
                }}】
              </td>
              <td>
                <button
                  v-if="itemInfo.isReturn === 0"
                  type="button"
                  class="btn btn-primary"
                  @click="returnItem(itemInfo)"
                >
                  归还
                </button>
                <span v-else>-</span>
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <CSTable v-show="tabBarType === 1" :thead-top="filterHeight">
        <template v-slot:header>
          <div class="table-header-panel text-right">
            <div class="sticky-right">
              <button
                type="button"
                class="btn btn-primary btn-sm"
                @click="newInWarehouse()"
              >
                <svg class="icon" aria-hidden="true">
                  <use xlink:href="#icon-menujiahao"></use>
                </svg>
                入库
              </button>
              <button
                type="button"
                class="btn btn-primary btn-sm"
                style="margin-left: 20px"
                @click="downloadOutFile('in')"
              >
                下载入库记录
              </button>
            </div>
          </div>
        </template>
        <template v-slot:thead>
          <tr>
            <th>
              <div
                style="
                  display: flex;
                  align-items: center;
                  justify-content: center;
                "
              >
                <span style="vertical-align: super; margin-left: 5px"
                  >选择</span
                >
              </div>
            </th>
            <th>入库时间</th>
            <th>物品名称</th>
            <th>入库单价(元)</th>
            <th>出库单价(元)</th>
            <th>入库数量</th>
            <th>入库后数量</th>
            <th>附件</th>
            <th>备注</th>
            <th>验收人</th>
            <th>创建人</th>
          </tr>
        </template>
        <template v-slot:tbody>
          <template v-for="itemInfo in inWarehouseList">
            <tr :data-id="itemInfo.id" :key="itemInfo.id">
              <td>
                <input
                  v-model="targetInFileList"
                  :value="itemInfo.id"
                  type="checkbox"
                />
              </td>
              <td class="date-td">{{ itemInfo.storeTime }}</td>
              <td>
                <span class="allow-click" @click="itemInterface(itemInfo)">
                  {{ itemInfo.goodsName }}
                </span>
              </td>
              <td>{{ itemInfo.storePrice }}</td>
              <td>{{ itemInfo.deliverPrice }}</td>
              <td>{{ itemInfo.storeCount }}</td>
              <td>{{ itemInfo.goodsCount }}</td>
              <td>
                <span
                  v-if="itemInfo.annex !== '[]' && itemInfo.annex"
                  class="allow-click"
                  @click="fileInterface(itemInfo, tabBarType)"
                  >查看</span
                >
                <span v-else> - </span>
              </td>
              <td>
                <span
                  v-if="itemInfo.comment"
                  class="allow-click"
                  @click="noteInterface(itemInfo)"
                  >查看</span
                >
                <span v-else> - </span>
              </td>
              <td>
                {{ itemInfo.acceptorName }}【{{ itemInfo.acceptorJobTitle }}】
              </td>
              <td>
                {{ itemInfo.createUserName }}【{{
                  itemInfo.createUserJobTitle
                }}】
              </td>
            </tr>
          </template>
        </template>
      </CSTable>
      <Pagination name="pagination" componentName="Pagination"></Pagination>
    </div>
    <!-- 物品名称 -->
    <CSDialog
      class="tenant-detail-dialog"
      dialog-width="540px"
      dialog-header-class="alert-bg"
      :dialog-visible="itemInfo.visible"
      @onClose="itemInfo.visible = false"
      :dialog-show-confirm-btn="false"
      dialog-title="物品信息"
      dialog-cancel-btn-text="关闭"
    >
      <template v-slot:dialog-content>
        <div
          style="
            max-height: 550px;
            overflow-y: auto;
            font-size: 24px;
            padding: 30px;
          "
        >
          <div class="preview-tenant">
            <p class="field">
              <span class="field-label">物品名称</span>
              <span class="field-content">
                {{ itemInfo.itemTarget.goodsName }}
              </span>
            </p>
            <p class="field">
              <span class="field-label">品牌型号</span>
              <span class="field-content">
                {{ itemInfo.itemTarget.model }}
              </span>
            </p>
            <p class="field">
              <span class="field-label">物品类型</span>
              <span class="field-content">
                {{ itemInfo.itemTarget.typeName }}
              </span>
            </p>
            <p class="field">
              <span class="field-label">存放位置</span>
              <span class="field-content">
                {{ itemInfo.itemTarget.specificLocation }}
              </span>
            </p>
            <p class="field">
              <span class="field-label">供应商</span>
              <span class="field-content">{{
                itemInfo.itemTarget.companyName
              }}</span>
            </p>
            <p class="field">
              <span class="field-label">对接人</span>
              <span class="field-content">{{
                itemInfo.itemTarget.dockingPeople
              }}</span>
            </p>
            <p class="field">
              <span class="field-label">对接人手机号</span>
              <span class="field-content">{{
                itemInfo.itemTarget.dockingPeoplePhone
              }}</span>
            </p>
          </div>
        </div>
      </template>
    </CSDialog>
    <!-- 备注 -->
    <CSDialog
      class="tenant-detail-dialog"
      dialog-width="450px"
      dialog-header-class="alert-bg"
      :dialog-visible="noteInfo.visible"
      @onClose="noteInfo.visible = false"
      :dialog-show-confirm-btn="false"
      dialog-title="备注"
      dialog-cancel-btn-text="关闭"
    >
      <template>
        <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
          <template>
            <div style="text-align: center">
              {{ noteInfo.noteDetail.comment }}
            </div>
          </template>
        </div>
      </template>
    </CSDialog>
    <!-- 添加出库 -->
    <Outwarehouse></Outwarehouse>
    <!-- 添加入库 -->
    <Inwarehouse></Inwarehouse>
    <!-- 再次确认 -->
    <CSDialog
      class="tenant-detail-dialog"
      dialog-width="450px"
      style="text-align: center"
      :dialog-visible="isConfirm"
      @onClose="isConfirm = false"
      @onConfirm="addNewItem()"
      dialog-title="提示"
      :dialog-confirm-btn-text="confirmTxt.confirmBtnTxt"
    >
      <template>
        <div slot="dialog-content" style="padding: 20px 30px; font-size: 24px">
          <template>
            <div class="field-preview">
              <p class="field">
                <span class="field-label">{{ confirmTxt.txt }}</span>
              </p>
            </div>
          </template>
        </div>
      </template>
    </CSDialog>
  </div>
</template>

<script>
import CSSelect from "@/components/common/CSSelect";
import CSDialog from "@/components/common/CSDialog";
import CSTabBar from "@/components/common/CSTabBar";
import Pagination from "@/components/Pagination";
import Outwarehouse from "@/components/warehouse/outWarehouse";
import Inwarehouse from "@/components/warehouse/inWarehouse";

import dayjs from "dayjs";
import { STORAGE_KEY } from "@/constant";

import {
  outWarehouseUrl,
  warehouseItemUrl,
  addInWarehouseUrl,
  addOutWarehouseUrl,
  returnOutWarehouseUrl,
  inWarehouseUrl,
  downloadInWarehouseUrl,
  queryDepartmentStaffUrl,
  warehouseSupplierUrl,
  downloadOutWarehouseUrl,
} from "@/requestUrl";
import CSTable from "@/components/common/CSTable";

export default {
  components: {
    CSTable,
    CSSelect,
    CSDialog,
    Pagination,
    CSTabBar,
    Outwarehouse,
    Inwarehouse,
  },
  data() {
    return {
      loading: true,
      filterHeight: 0,
      // 查询条件
      searchTerm: {
        startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD 00:00"),
        endDate: dayjs().format("YYYY-MM-DD 23:59"),
        itemName: "",
        itemType: "",
        supplier: "",
        accepctor: "",
        itemIsReturn: "",
        itemReason: "",
        itemAcpPer: "",
      },
      isCheck: "",
      isConfirm: false,
      itemList: [],
      supplierList: [],
      // 物品类型列表
      confirmTxt: {
        txt: "",
        confirmBtnTxt: "",
      },
      itemTypeList: [
        { id: 1, name: "零件" },
        { id: 2, name: "消耗品" },
        { id: 3, name: "工具" },
        { id: 4, name: "工服" },
        { id: 5, name: "其它" },
      ],
      itemReasonList: [
        { id: 1, name: "公共维修" },
        { id: 2, name: "客户维修" },
        { id: 3, name: "日常维保" },
        { id: 4, name: "日常消耗" },
        { id: 5, name: "突发事件" },
        { id: 6, name: "其它" },
      ],
      itemIsReturnList: [
        { id: 1, name: "归还" },
        { id: 2, name: "不归还" },
      ],
      // 领取人列表
      itemAcpPerList: [],
      tabBarType: 0,
      tabBarList: {
        0: "出库记录",
        1: "入库记录",
      },
      newInInfo: {},
      inWarehouseList: [],
      newOutInfo: {},
      returnItemInfo: {},
      outWarehouseList: [],
      itemInfo: {
        visible: false,
        itemTarget: {},
      },
      noteInfo: {
        visible: false,
        noteDetail: {},
      },
      targetOutFileList: [],
      targetInFileList: [],
      pageNo: 1,
    };
  },
  created() {
    window.addEventListener("keydown", this.getWarehouseItemDown);
    if (JSON.stringify(this.tabBarList) !== "{}") {
      this.turnTabBar();
    }
    this.getAcpStaff();
    this.getWarehouseItemList();
    this.getSupplier();
    this.$vc.on(this.$route.path, "newWarehouseItem", "done", (data) => {
      this.confirmTxt.txt = "提交后将无法修改信息，确定提交吗?";
      this.confirmTxt.confirmBtnTxt = "提交";
      if (this.tabBarType === 0) {
        this.newOutInfo = data.itemInfo;
      } else {
        this.newInInfo = data.itemInfo;
      }
      this.addNewItem();
    });
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.getWarehouseItemDown);
  },
  deactivated(){
    window.removeEventListener("keydown", this.getWarehouseItemDown);
  },
  mounted() {
    this.$vc.on(this.$route.path, "pagination", "page_event", (currentPage) => {
      this.getWarehouseItem(currentPage);
    });
    this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    window.addEventListener("resize", () => {
      this.filterHeight = document.querySelector(".filter-panel").offsetHeight;
    });
  },
  methods: {
    getWarehouseItemDown(e) {
      if (e.keyCode == 13) {
        this.getWarehouseItem();
      }
    },
    turnTabBar() {
      if (
        !this.$vc.isEmpty(sessionStorage.getItem(STORAGE_KEY.WAREHOUSE_TABID))
      ) {
        this.changeTabBar(+sessionStorage.getItem(STORAGE_KEY.WAREHOUSE_TABID));
        return;
      }
      this.changeTabBar();
    },
    getAcpStaff() {
      this.$fly
        .post(queryDepartmentStaffUrl, {
          regionCode: this.$vc.getCurrentRegion().communityId,
          dutyType: "",
        })
        .then((res) => {
          res.data.forEach((item) => {
            if (!item.resignTime) {
              this.itemAcpPerList.push(item);
            }
          });
        });
    },
    getWarehouseItemList() {
      this.$fly
        .post(warehouseItemUrl, {
          regionCode: this.$vc.getCurrentRegion().communityId,
          search: "",
          state: 1,
          type: "",
          supplierId: "",
          isWarning: "",
        })
        .then((res) => {
          this.itemList = res.data.datas;
        });
    },
    getSupplier() {
      this.$fly
        .post(warehouseSupplierUrl, {
          regionCode: this.$vc.getCurrentRegion().communityId,
          state: 1,
        })
        .then((res) => {
          this.supplierList = res.data.datas || res.data;
        });
    },
    getWarehouseItem(pageNo = this.pageNo, pageSize = 10) {
      if (+this.tabBarType === 0) {
        this.$fly
          .post(outWarehouseUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            startTime: this.searchTerm.startDate,
            endTime: this.searchTerm.endDate,
            goodsId: this.searchTerm.itemName,
            goodsType: this.searchTerm.itemType,
            supplierId: this.searchTerm.supplier,
            causeType: this.searchTerm.itemReason,
            type: this.searchTerm.itemIsReturn,
            receiver: this.searchTerm.accepctor,
            pageNo,
            pageSize,
          })
          .then((res) => {
            this.pageNo = pageNo;
            this.$vc.emit("loading", {});
            this.loading = false;
            this.outWarehouseList = res.data.datas || res.data;
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
            console.log(this.loading)
            var outList = this.outWarehouseList;
            var reaList = this.itemReasonList;
            var backList = this.itemIsReturnList;
            outList.forEach((item) => {
              reaList.forEach((rea) => {
                if (item.causeType === rea.id) {
                  item.causeName = rea.name;
                }
              });
              backList.forEach((back) => {
                if (item.type === 2) {
                  item.returnTime = back.name;
                }
              });
              if (item.isReturn === 1) {
                item.returnTime += " (已归还)";
              } else if (item.isReturn === 0) {
                item.returnTime += " (未归还)";
              }
            });
          });
      } else {
        this.$fly
          .post(inWarehouseUrl, {
            regionCode: this.$vc.getCurrentRegion().communityId,
            startTime: this.searchTerm.startDate,
            endTime: this.searchTerm.endDate,
            goodsId: this.searchTerm.itemName,
            goodsType: this.searchTerm.itemType,
            supplierId: this.searchTerm.supplier,
            acceptor: this.searchTerm.itemAcpPer,
            pageNo,
            pageSize,
          })
          .then((res) => {
            this.pageNo = pageNo;
            this.inWarehouseList = res.data.datas || res.data;
            this.$vc.emit("loading", {});
            this.loading = false
            if (pageNo === 1) {
              this.$vc.emit(this.$route.path, "pagination", "init", {
                total: res.data.total || res.data.datas.length,
                currentPage: pageNo,
                pageSize,
              });
            }
          });
      }
    },
    fileInterface(item, tabBar) {
      let id = item.id;
      this.$nextTick(() => {
        this.$router.push({
          name: "file",
          params: {
            id,
            tabBar,
          },
        });
      });
    },
    noteInterface(target) {
      if (this.tabBarType === 1) {
        this.noteInfo = {
          visible: true,
          noteDetail: target,
        };
      } else {
        this.noteInfo = {
          visible: true,
          noteDetail: target,
        };
      }
    },
    itemInterface(target) {
      for (var i = 0; i < this.itemReasonList.length; i++) {
        if (target.type === this.itemReasonList[i].id) {
          target.typeName = this.itemReasonList[i].name;
          break;
        }
      }
      this.itemInfo = {
        visible: true,
        itemTarget: target,
      };
    },
    changeTabBar(index = 0) {
      this.tabBarType = index;
      this.getWarehouseItem();
    },
    newOutWarehouse() {
      this.$vc.emit(this.$route.path, "newWarehouseItem", "out", {
        boolean: true,
        itemList: this.itemList,
      });
    },
    newInWarehouse() {
      this.$vc.emit(this.$route.path, "newWarehouseItem", "in", {
        boolean: true,
        itemList: this.itemList,
      });
    },
    addNewItem() {
      if (this.tabBarType === 0 && this.returnItemInfo.visible !== 1) {
        this.$CSDialog.warning({
          title: "提示",
          messageHtml: "<span>提交后将无法修改信息,确认提交吗?</span>",
          onConfirm: () => {
            this.$fly
              .post(addOutWarehouseUrl, {
                regionCode: this.$vc.getCurrentRegion().communityId,
                goodsId: this.newOutInfo.itemName,
                deliverPrice: +this.newOutInfo.itemOutPrice,
                deliverTime: this.newOutInfo.itemOutDate,
                deliverCount: +this.newOutInfo.itemOutQuantity,
                causeType: this.newOutInfo.itemReason,
                type: +this.newOutInfo.itemIsReturn,
                returnTime: this.newOutInfo.itemReturnDate,
                annex: JSON.stringify(this.newOutInfo.itemFile),
                comment: this.newOutInfo.itemNote,
                receiver: this.newOutInfo.itemAcpWorker,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.getWarehouseItem();
                this.$vc.toast("添加出库成功");
              });
          },
        });
      } else if (this.tabBarType === 1) {
        this.$CSDialog.warning({
          title: "提示",
          messageHtml: "<span>提交后将无法修改,确认提交吗?",
          onConfirm: () => {
            this.$fly
              .post(addInWarehouseUrl, {
                regionCode: this.$vc.getCurrentRegion().communityId,
                goodsId: this.newInInfo.itemName,
                storePrice: this.newInInfo.itemInPrice,
                deliverPrice: this.newInInfo.itemOutPrice,
                storeTime: this.newInInfo.itemInDate,
                storeCount: this.newInInfo.itemInQuantity,
                annex: JSON.stringify(this.newInInfo.itemFile),
                comment: this.newInInfo.itemNote,
                acceptor: this.newInInfo.itemAcpWorker,
              })
              .then((res) => {
                if (res.code !== 0) {
                  return;
                }
                this.$CSDialog.instance.closeDialog();
                this.getWarehouseItem();
                this.$vc.toast("添加入库成功");
              });
          },
        });
      }
    },
    downloadOutFile(state) {
      var downloadTime = this.$vc.dateFormat(new Date().getTime());
      if (state === "in") {
        if (this.targetInFileList.length) {
          this.$fly
            .post(
              downloadInWarehouseUrl,
              {
                regionCode: this.$vc.getCurrentRegion().communityId,
                ids: this.targetInFileList,
              },
              {
                responseType: "arraybuffer",
                headers: {
                  notParse: true,
                },
              }
            )
            .then((res) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              const fileName = `${downloadTime}入库记录.xlsx`;
              if ("download" in document.createElement("a")) {
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
              } else {
                navigator.msSaveBlob(blob, fileName);
              }
            });
        } else {
          this.$vc.toast("请选择需要下载的入库数据");
        }
      } else if (state === "out") {
        if (this.targetOutFileList.length) {
          this.$fly
            .post(
              downloadOutWarehouseUrl,
              {
                regionCode: this.$vc.getCurrentRegion().communityId,
                ids: this.targetOutFileList,
              },
              {
                responseType: "arraybuffer",
                headers: {
                  notParse: true,
                },
              }
            )
            .then((res) => {
              const blob = new Blob([res], {
                type: "application/vnd.ms-excel",
              });
              const fileName = `${downloadTime}出库记录.xlsx`;
              if ("download" in document.createElement("a")) {
                const elink = document.createElement("a");
                elink.download = fileName;
                elink.style.display = "none";
                elink.href = URL.createObjectURL(blob);
                document.body.appendChild(elink);
                elink.click();
                URL.revokeObjectURL(elink.href);
                document.body.removeChild(elink);
              } else {
                navigator.msSaveBlob(blob, fileName);
              }
            });
        } else {
          this.$vc.toast("请选择需要下载的出库数据");
        }
      }
    },
    returnItem(target) {
      this.returnItemInfo.obj = target;
      this.returnItemInfo.visible = 1;
      this.$CSDialog.warning({
        title: "提示",
        messageHtml: `<span>确定归还吗?</span>`,
        onConfirm: () => {
          this.$fly
            .post(returnOutWarehouseUrl, {
              regionCode: this.$vc.getCurrentRegion().communityId,
              id: this.returnItemInfo.obj.id,
            })
            .then((res) => {
              if (res.code !== 0) {
                return;
              }
              this.$CSDialog.instance.closeDialog();
              this.returnItemInfo.obj.visible = 2;
              this.getWarehouseItem();
            });
        },
      });
    },
  },
  watch: {
    tabBarType: function () {
      this.searchTerm = {
        startDate: dayjs().subtract(1, "month").format("YYYY-MM-DD 00:00"),
        endDate: dayjs().format("YYYY-MM-DD 23:59"),
        itemName: "",
        itemType: "",
        supplier: "",
        accepctor: "",
        itemReason: "",
        itemAcpPer: "",
        itemIsReturn: "",
      };
      this.isCheck = "";
      this.targetInFileList = [];
      this.targetOutFileList = [];
    },
    pageNo: function () {
      this.isCheck = "";
    },
  },
  destroyed() {
    sessionStorage.setItem(STORAGE_KEY.WAREHOUSE_TABID, 0);
  },
};
</script>

<style lang="stylus">
.tenant-detail-dialog {
  .el-dialog__header {
    display: none;
  }

  .preview-tenant {
    color: #000;

    .field {
      &-label {
        width: 200px;
        display: inline-block;
        margin-right: 40px;
        text-align: right;
        vertical-align: top;
      }

      &-content {
        display: inline-block;
        max-width: calc(100% - 240px);
      }
    }
  }

  .el-divider__text.is-left {
    font-size: 24px;
  }
}
</style>
